import React from "react"
import { Text, Flex } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import styled from "styled-components"
import CircleIcon from "../../../ui/components/CircleIcon"

const BigImage = styled.img`
  max-width: 100%;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid black;
`

const PlayButton = styled(CircleIcon)`
  position: absolute;
  top: 40%;
  left: 45%;
`

const LargeWebinar = ({ featured }) => {
  const mainImageSizing = {
    width: [1 / 1, 1 / 1, "60%"],
    height: "100%"
  }
  return (
    <Flex
      width={mainImageSizing.width}
      mr={[0, 0, 3]}
      mb={[3, 3, 0]}
      height="100%"
      maxHeight={mainImageSizing.height}
      flexDirection="column"
    >
      <Link
        to={`network/webinars/${featured.id}`}
        width={1 / 1}
        height="auto"
        maxheight={mainImageSizing.height}
        style={{ position: "relative" }}
      >
        <PlayButton bg="dark.0" iconColor="light.0" name="play_arrow" />
        <BigImage
          src={featured.coverImage}
          alt="Webinar"
          width="100%"
          style={{ maxheight: mainImageSizing.height }}
        />
        <Flex justifyContent="center">
          <Flex
            width={996 / 1000}
            height="auto"
            p={3}
            style={{
              position: "absolute",
              bottom: "2px",
              borderRadius: "4px"
            }}
            flexDirection="column"
            bg="rgba(255, 255, 255, 0.8)"
          >
            <Text fontWeight={700} fontSize={[4, 6, 6]}>
              Catch up with our Network Webinar/Podcast on: {featured.title}
            </Text>
          </Flex>
        </Flex>
      </Link>
    </Flex>
  )
}

export default LargeWebinar
