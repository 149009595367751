/* eslint-disable react/jsx-curly-newline */

/* eslint-disable no-nested-ternary */
import {
  Box,
  Flex,
  IconButton,
  RoundedDropdown,
  Text
} from "@engaging-tech/components"
import React, { useState } from "react"
import styled from "styled-components"

import ManageTargetChoiceContainer from "../../containers/Branching/ManageTargetChoices"

const Option = styled.option`
  color: ${({ theme, required, section, disabled, end }) =>
    disabled
      ? theme.colors.dark[3]
      : section || end
      ? theme.colors.light[0]
      : required
      ? theme.colors.error[0]
      : theme.colors.dark[0]};
  background-color: ${({ theme, section, end }) =>
    (section || end) && theme.colors.secondary[0]};
`

const ChooseTargetQuestion = ({
  target,
  setBranchingLogic,
  currentChoice,
  currentQuestion,
  availableTargetQuestions,
  nextSection,
  isLastQuestion
}) => {
  return (
    <RoundedDropdown
      onChange={e =>
        setBranchingLogic(
          currentChoice.id,
          currentQuestion.id,
          e.target.value,
          []
        )
      }
      value={
        // For selecting to lead to to next question
        target?.id ||
        // For selecting to lead to next section
        target?.sectionId ||
        // For selecting to lead to next section if the currentQuestion is last question in section but theres another section after (visual)
        (isLastQuestion && nextSection && nextSection.sectionId) ||
        // For selecting to lead to end survey if the currentQuestion is last question in section and theres no section after (visual)
        (!target?.sectionId && !target?.id && "end")
      }
      width="250px"
    >
      {availableTargetQuestions?.map(({ title, id: optionId, required }) => (
        <Option
          key={currentQuestion.id + optionId}
          value={optionId}
          required={required}
          disabled={currentQuestion.id === optionId}
        >
          {title}
        </Option>
      ))}
      {nextSection && (
        <Option section value={nextSection?.sectionId}>
          Section: {nextSection?.name}
        </Option>
      )}
      {!nextSection && (
        <Option end value="end">
          End of survey
        </Option>
      )}
    </RoundedDropdown>
  )
}

const ManageChoice = ({
  currentChoice,
  currentQuestion,
  nextSection,
  availableTargetQuestions,
  setBranchingLogic,
  target,
  areAllAnswersAvailable,
  isLastQuestion
}) => {
  const [openModal, setOpenModal] = useState(false)
  return (
    <Flex
      mt={5}
      alignItems="center"
      width={1 / 1}
      justifyContent="space-between"
    >
      <Text width="fit-content">Answering</Text>
      <Text color="primary.0" width={1 / 1} maxWidth="100px" minWidth="50px">
        {currentChoice?.value.toString() || "Untitled choice"}
      </Text>
      <Text width="fit-content" maxWidth="100px" minWidth="50px">
        leads to
      </Text>

      <ChooseTargetQuestion
        target={target}
        setBranchingLogic={setBranchingLogic}
        currentChoice={currentChoice}
        currentQuestion={currentQuestion}
        nextSection={nextSection}
        availableTargetQuestions={availableTargetQuestions}
        isLastQuestion={isLastQuestion}
      />
      {!!target?.id ||
      !!target?.sectionId ||
      (isLastQuestion && nextSection) ? (
        <Flex width="fit-content">
          <Text width="fit-content">with</Text>
          <Text
            width="60px"
            textAlign="center"
            color={areAllAnswersAvailable ? "secondary.0" : "primary.0"}
          >
            {areAllAnswersAvailable ? "all" : "some"}
          </Text>
          <Text width="fit-content">values available</Text>
        </Flex>
      ) : (
        <Flex width="210px" />
      )}

      {target?.type === "dropdown" && !target?.isCore ? (
        <IconButton icon="edit" onClick={() => setOpenModal(true)} />
      ) : (
        <Box width="48px" />
      )}

      {openModal && (
        <ManageTargetChoiceContainer
          setOpenModal={setOpenModal}
          currentChoice={currentChoice}
          target={target}
          currentQuestionId={currentQuestion.id}
        />
      )}
    </Flex>
  )
}

export default ManageChoice
